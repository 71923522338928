import { useIsCatalogLandingPopupOpenedValue } from '@/atoms/opened-popups';
import { AnimatePresence } from 'framer-motion';
import { ImageShape } from '@/types';
import classNames from 'classnames';
import { useCatalogLandingAnimationValue } from '@/atoms/catalog-landing/animation-state';
import CatalogLandingCard from './CatalogLandingCard';
import { CategoryId } from '@/api/categories';

type Props = {
    categories: {
        id: CategoryId;
        name: string;
        slug: string;
        image: ImageShape | null;
        lottie: string | null;
        amount: number | null;
    }[];
};

const SecondStep = ({ categories }: Props) => {
    const isOpened = useIsCatalogLandingPopupOpenedValue();
    const animationState = useCatalogLandingAnimationValue();
    return (
        <ul
            className={classNames(
                'catalog-landing-step catalog-landing-step--second list-unstyled catalog-landing-step--active',
                {
                    'catalog-landing--animating': animationState,
                    'catalog-landing-step--second--two-categories': categories.length < 3,
                },
            )}
        >
            {categories.map((category, i) => {
                return (
                    <li key={i} className="catalog-landing-second-item">
                        <button className="catalog-landing-second-item__button">
                            <AnimatePresence>
                                {isOpened && (
                                    <CatalogLandingCard
                                        categoryId={category.id}
                                        categoryName={category.name}
                                        amount={category.amount}
                                        lottie={category.lottie}
                                        key={category.id}
                                    />
                                )}
                            </AnimatePresence>
                        </button>
                    </li>
                );
            })}
        </ul>
    );
};

export default SecondStep;
