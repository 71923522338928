import { useState } from 'react';
import { m } from 'framer-motion';
import Button from '@/components/shared/Button';
import { useSetCatalogLandingAnimationState } from '@/atoms/catalog-landing/animation-state';
import dynamic from 'next/dynamic';
import { useSetOpenedPopupsState } from '@/atoms/opened-popups';
import { CATALOG_LANDING_POPUP_NAME } from './CatalogLandingPopup';
import { useCatalogLandingProductsType } from '@/atoms/catalog-landing/products-type';
import Link from '@/components/shared/Link';
import { CategoryId } from '@/api/categories';
import { fromCDN } from '@/cdn';
import { useAppReadyState } from '@/atoms/app-ready';

type Props = {
    categoryId: CategoryId;
    categoryName: string;
    amount?: number | null;
    lottie?: string | null;
};

const MotionButton = m(Button);
const LottieAnimation = dynamic(() => import('../../shared/LottieAnimation'), { ssr: false });

const CatalogLandingCard = ({ categoryId, categoryName, amount, lottie }: Props) => {
    const { closePopup } = useSetOpenedPopupsState();
    const [hovered, setHovered] = useState(false);
    const setAnimationState = useSetCatalogLandingAnimationState();
    const [type] = useCatalogLandingProductsType();
    const appReady = useAppReadyState();

    const availableStr = type === 'all' ? '' : type === 'available' ? '&available=1' : `&available=0`;
    return (
        <Link
            href={`/catalog?category=${categoryId}${availableStr}&sort=published_at&order=desc`}
            className="catalog-landing-card"
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            onTouchStart={() => setHovered(true)}
            onTouchEnd={() => setHovered(false)}
            onClick={() => {
                closePopup(CATALOG_LANDING_POPUP_NAME);
                sessionStorage.removeItem('scrollPosition');
            }}
        >
            <div className="catalog-landing-card__inner">
                {lottie && (
                    <m.div
                        initial={{ y: '100%' }}
                        animate={{
                            y: 0,
                            transition: {
                                delay: 0.4,
                                duration: 0.4,
                            },
                        }}
                        exit={{
                            y: '-100%',
                            transition: {
                                duration: 0.6,
                            },
                        }}
                        className="clip-container catalog-landing-lottie-outer"
                        key={`second-lottie-${categoryId}`}
                    >
                        <m.div
                            initial={{ y: '-100%' }}
                            animate={{
                                y: 0,
                                transition: {
                                    delay: 0.4,
                                    duration: 0.4,
                                },
                            }}
                            exit={{
                                y: '100%',
                                transition: {
                                    duration: 0.6,
                                },
                            }}
                            className="catalog-landing-lottie-wrapper"
                        >
                            {appReady && (
                                <LottieAnimation
                                    path={fromCDN(`/lottie/${lottie}.json`)}
                                    paused={!hovered}
                                    className="catalog-landing-lottie"
                                />
                            )}
                        </m.div>
                    </m.div>
                )}
                <m.div
                    initial={{ y: '100%' }}
                    animate={{
                        y: 0,
                        transition: {
                            delay: 0.4,
                            duration: 0.4,
                        },
                    }}
                    exit={{
                        y: '-100%',
                        transition: {
                            duration: 0.6,
                        },
                    }}
                    onAnimationStart={() => {
                        setAnimationState(true);
                    }}
                    onAnimationComplete={() => {
                        setAnimationState(false);
                    }}
                    className="clip-container catalog-landing-card__name"
                    key={`second-title-${categoryId}`}
                >
                    <MotionButton
                        tag="div"
                        geometryVariant="large"
                        initial={{ y: '-100%' }}
                        animate={{
                            y: 0,
                            transition: {
                                delay: 0.4,
                                duration: 0.4,
                            },
                        }}
                        exit={{
                            y: '100%',
                            transition: {
                                duration: 0.6,
                            },
                        }}
                    >
                        {categoryName}
                    </MotionButton>
                </m.div>
                <m.div
                    className="clip-container m-text-xs catalog-landing-card__amount"
                    initial={{ y: '100%' }}
                    animate={{
                        y: 0,
                        transition: {
                            delay: 0.4,
                            duration: 0.4,
                        },
                    }}
                    exit={{
                        y: '-100%',
                        transition: {
                            duration: 0.6,
                        },
                    }}
                    key={`second-amount-${categoryId}`}
                >
                    <m.div
                        initial={{ y: '-100%' }}
                        animate={{
                            y: 0,
                            transition: {
                                delay: 0.4,
                                duration: 0.4,
                            },
                        }}
                        exit={{
                            y: '100%',
                            transition: {
                                duration: 0.6,
                            },
                        }}
                    >
                        [ {amount} ]
                    </m.div>
                </m.div>
            </div>
        </Link>
    );
};

export default CatalogLandingCard;
