import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

export const catalogLandingAnimationState = atom<boolean>({
    key: 'catalogLandingAnimationState',
    default: false,
});

export const useCatalogLandingAnimationValue = () => useRecoilValue(catalogLandingAnimationState);

export const useSetCatalogLandingAnimationState = () => useSetRecoilState(catalogLandingAnimationState);
